import { Button, ButtonStyle } from '../UI/Button'
import waitingImg from '../../assets/LandingPage/Home/waiting.svg'
import waitingImgMobile from '../../assets/LandingPage/Home/waiting-mobile.webp'
import waitingImgTablet from '../../assets/LandingPage/Home/waiting-tablet.webp'
import classNames from 'classnames'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export const WaitingFor = ({
  buttonChild,
  title,
  desc,
  image = waitingImg,
  mobileImg = waitingImgMobile,
  tabletImg = waitingImgTablet,
  buttonHref = '/sign-in',
  imageStyle = 'absolute bottom-2 right-0 left-0 mx-auto object-contain z-0',
  customComponent = null,
}: {
  buttonChild?: React.ReactNode
  title: string
  desc: string
  image?: string
  imageStyle?: string
  mobileImg?: string
  tabletImg?: string
  buttonHref?: string
  customComponent?: React.ReactNode
}): JSX.Element => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (buttonHref.startsWith('#')) {
      event.preventDefault()
      const targetElement = document.getElementById(buttonHref.substring(1))
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  return (
    <div className='flex flex-col-reverse md:flex-col bg-blue-500 pt-16 pb-12 relative'>
      <LazyLoadImage
        className={classNames(imageStyle, {
          ['hidden md:block']: mobileImg && tabletImg,
        })}
        src={image}
        alt='waiting-img'
      />
      {tabletImg && (
        <LazyLoadImage
          className={classNames(imageStyle, 'hidden xs:block md:hidden')}
          src={tabletImg}
          alt='waiting-img'
        />
      )}
      {mobileImg && (
        <LazyLoadImage
          className={classNames(imageStyle, 'xs:hidden')}
          src={mobileImg}
          alt='waiting-img'
        />
      )}
      <div className='w-full max-w-screen-lg mx-auto mb-24 relative z-1 px-8 lg:px-0'>
        <div className='flex flex-col md:flex-row items-start text-white'>
          <p className='flex-1 text-2xl font-bold pr-4'>{title}</p>
          <div className='flex-1 mt-6 md:mt-0 mb-24 xs:mb-8 md:mb-0'>
            <p className='mb-6'>{desc}</p>
            {customComponent ? (
              <div>{customComponent}</div>
            ) : (
              <a
                href={buttonHref}
                onClick={handleClick}
                className='flex items-center justify-center bg-yellow-500 hover:bg-yellow-600 text-black rounded-md font-semibold transition duration-200 ease-in-out max-w-max p-3 sm:px-6 sm:py-3'
              >
                {buttonChild}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
